const deepmerge = require('deepmerge');
const { CAMEL_CASE_PARAMS } = require('../common/constants');

/**
 * CUSTOM
 * `get` returns the value from a key-pair Object given a key.
 *      - it will return `defaultValue` if `key` is not found.
 *      - use dot notation for nested properties (e.g. `this.is.a.nested.property`)
 * @param dict: Object
 * @param key: String
 * @param defaultValue: any
 * @returns any
 */
function get(dict, key, defaultValue = undefined) {
    function _get(subDict, keys, lastValue) {
        if (!keys.length) {
            return lastValue;
        }
        const curKey = keys.shift();
        const curValue = subDict[curKey];
        if (curValue === undefined) {
            return defaultValue;
        }
        return _get(curValue, keys, curValue);
    }

    const keys = key.split('.');
    return _get(dict, keys, defaultValue);
}

/**
 * Returns an Object representing the intersection between `keys` and `dict`.
 *
 * @param dict: Object
 * @param keys: Set<string>
 * @returns Object
 */
function pick(dict, keys) {
    if (!dict) {
        return {};
    }

    const subsetDict = {};
    for (const k of keys) {
        if (dict[k]) {
            subsetDict[k] = dict[k];
        }
    }
    return subsetDict;
}

// CUSTOM: add `prefix` argument
function appIdToNameAndSlot(appId, addPortalPrefix = true) {
    const [appNameWithoutPrefix, slotName] = appId.split('__at__');

    // Case for shared libraries
    if (appNameWithoutPrefix === undefined || slotName === undefined) {
        return {
            appName: appId,
            slotName: 'none',
        };
    }

    return {
        // CUSTOM: remove `@portal` prefix which causes registryConf to return undefined apps in setupErrorHandlers.js
        appName: addPortalPrefix ? `@portal/${appNameWithoutPrefix}` : appNameWithoutPrefix,
        slotName,
    };
}

function makeAppId(appName, slotName) {
    return `${appName.replace('@portal/', '')}__at__${slotName}`;
}

function cloneDeep(source) {
    return deepmerge({}, source);
}

const uniqueArray = array => [...new Set(array)];

function removeEndSlashUrl(url) {
    return url.replace(/\/$|\/(?=\?)/, "");
}

function removeDoubleParams(query) {
    var querySanitized = query;
    for (const [key, value] of Object.entries(query)) {
        if (Array.isArray(value)) {
            querySanitized[key] = value[0]
        }
    }
    return querySanitized
}

function lowerCaseToCamelCaseParams(query) {
    let querySanitized = query;

    for (const [key, value] of Object.entries(query)) {
        if (CAMEL_CASE_PARAMS[key]) {
            querySanitized[CAMEL_CASE_PARAMS[key]] = value
            delete querySanitized[key];
        }
    }
    return querySanitized
}

function removeBackOffersParams(query) {
    delete query?.bo;
    return query;
}

function generateRouteProps(currentRoute) {
    if (!currentRoute) {
        return
    }

    if (!currentRoute.route || !currentRoute.meta || !currentRoute.meta.isSpecialPattern) {
        if (!currentRoute.meta) {
            return
        }

        if (currentRoute.meta.siteConfig) {
            return { siteConfig: currentRoute.meta.siteConfig }
        }

        return
    }

    // avoid changing MFEs behaviour default routing
    const tempCurrentRoute = { ...currentRoute };

    if (currentRoute.route.includes('/*') || currentRoute.route.includes('//*')) {
        currentRoute.route = currentRoute.route.replace('//', '/');
        currentRoute.route = currentRoute.route.replace('/*', '/(.*)')
        currentRoute.basePath = currentRoute.reqUrl && currentRoute.reqUrl.split(/[?#]/)[0]
    }

    if (!currentRoute.meta.dynamicProps) {
        currentRoute = tempCurrentRoute;
        if (currentRoute.meta.siteConfig) {
            return { isSpecialRoute: true, siteConfig: currentRoute.meta.siteConfig }
        }
        return { isSpecialRoute: true }
    }

    const urlAllPaths = currentRoute.basePath.split('/').filter(Boolean);
    const urlStaticPaths = currentRoute.route.split('/(.*)').reduce((acc, path) => {
        const paths = path.split('/').filter(Boolean)
        return [...acc, ...paths]
    }, []);

    const urlDynamicPaths = urlAllPaths.filter(item => !urlStaticPaths.includes(item));
    const urlDynamicProps = currentRoute.meta.dynamicProps.reduce((acc, dynamicPart, index) => {
        return {
            ...acc,
            [dynamicPart]: urlDynamicPaths[index],
        };
    }, {});

    currentRoute = tempCurrentRoute;
    if (currentRoute.meta.siteConfig) {
        return { isSpecialRoute: true, urlDynamicProps, siteConfig: currentRoute.meta.siteConfig }
    }

    return { isSpecialRoute: true, urlDynamicProps }
}

const encodeHtmlEntities = value => value.replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
const decodeHtmlEntities = value => value.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&quot;/g, '"');

module.exports = {
    appIdToNameAndSlot,
    makeAppId,
    lowerCaseToCamelCaseParams,
    cloneDeep,
    uniqueArray,
    removeEndSlashUrl,
    removeDoubleParams,
    generateRouteProps,
    removeBackOffersParams,
    encodeHtmlEntities,
    decodeHtmlEntities,
    get,
    pick,
}
